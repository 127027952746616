<template>
  <Wating/>
</template>

<script>
import Wating from "@/components/Wating";
export default {
  name: "PostManage",
  components:{Wating}
}
</script>

<style scoped>

</style>